// import { Splide } from "@splidejs/splide";

import { gsap } from "gsap";

/* The following plugin is a Club GSAP perk */
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(MotionPathPlugin);

window.gsap = gsap;

const circlePath = MotionPathPlugin.convertToPath("#circle_holder", false)[0];
document.querySelector("#energy_circle").prepend(circlePath);

let items = gsap.utils.toArray(".circle_item");
let numItems = items.length;
let mm = gsap.matchMedia();

mm.add("(min-width: 800px)", () => {
    gsap.set(items, {
        motionPath: {
            path: circlePath,
            align: circlePath,
            autoRotate: false,
            alignOrigin: [0.5, 0.5],
            end: (i) => i / items.length,
        },
        scale: 0.9,
    });
});

mm.add("(max-width: 799px)", () => {
    gsap.set(items, {
        motionPath: {
            path: circlePath,
            align: circlePath,
            autoRotate: false,
            alignOrigin: [0.5, 0.5],
            end: (i) => i / items.length,
        },
        scale: 0.7,
    });
});

let tl = gsap.timeline({
    // paused: false,
    paused: true,
});

tl.to(items, {
    duration: 2000,
    ease: "linear",
    item: numItems,
    motionPath: {
        path: circlePath,
        align: circlePath,
        autoRotate: false,
        alignOrigin: [0.5, 0.5],
        start: (i) => i / items.length,
        end(i) {
            return i / items.length + 1;
        },
    },
});

const resizeHandler = () => {
    tl.invalidate();
    tl.play();
    pauseIt.restart(true);
};

const pauseOnload = () => {
    tl.invalidate();
    tl.pause();
};

const timer = gsap.delayedCall(0.4, resizeHandler).pause();
const pauseIt = gsap.delayedCall(0.5, pauseOnload).pause();

document.addEventListener("DOMContentLoaded", function () {
    timer.restart(true);
    tl.pause();
});

window.addEventListener("resize", () => {
    timer.restart(true);
    tl.pause();
});

window.tl = tl;
